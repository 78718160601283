import {useLoaderData} from '@remix-run/react';
import type {PropsWithChildren} from 'react';

import SkipLink from '@/components/shared/Page/SkipLink';
import useHandle from '@/hooks/useHandle';
import CookieNoticeBanner from '@/components/brochureV2/primitive/CookieNoticeBanner/CookieNoticeBanner';
import Wrapper from '@/components/shared/Page/Wrapper';
import {useTranslations} from '@/hooks/useTranslations';
import {EngineeringBlogHeader} from '@/pages/shopify.engineering/components/EngineeringBlogHeader/EngineeringBlogHeader';
import {EngineeringBlogFooter} from '@/pages/shopify.engineering/components/EngineeringBlogFooter/EngineeringBlogFooter';
import {EN_BLOG_PATH} from '@/components/blog/constants';

type PageLayoutProps = {
  title?: string;
  metaDescription?: string;
  isPureTitle?: boolean;
  hasScrollRestoration?: boolean;
  theme?: string;
};

export const EngineeringPageLayout = ({
  title,
  metaDescription,
  children,
  isPureTitle,
  hasScrollRestoration = false,
}: PropsWithChildren<PageLayoutProps>) => {
  const data = useLoaderData<any>();
  const {t} = useTranslations();
  const {mainClass, CustomModal} = useHandle();
  const {realCountryCode, rootDomain} = data;

  return (
    <Wrapper
      data={data}
      title={title}
      isPureTitle={isPureTitle}
      metaDescription={metaDescription}
      hasScrollRestoration={hasScrollRestoration}
    >
      {CustomModal && <CustomModal />}
      <SkipLink
        mode="dark"
        text={t('global:ariaLabels.header.skipToContent')}
      />
      <div className="relative bg-engineering-dark-bg text-engineering-dark-text [&_.app-signup-links]:hidden">
        <div className="bg-enginneering-dark bg-top bg-auto bg-no-repeat">
          <EngineeringBlogHeader enBlogPath={EN_BLOG_PATH.ENGINEERING} />
          <main role="main" id="main" tabIndex={-1} className={mainClass}>
            {children}
          </main>
          <EngineeringBlogFooter />
          <CookieNoticeBanner
            countryCode={realCountryCode}
            rootDomain={rootDomain}
          />
        </div>
      </div>
    </Wrapper>
  );
};
