import type {I18nRequestContext} from '@/types';
import {localizePathBySite} from '@/utils/site';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function getTopicsNav({t, site}: I18nRequestContext) {
  const navItems = [];
  const shopifyDescriptionEnabled = ![
    'da',
    'sv',
    'ko',
    'vi',
    'zh-HK',
    'zh-TW',
  ].includes(site.locale);

  if (shopifyDescriptionEnabled) {
    navItems.push({
      content: t('nav:whatIsShopify'),
      url: `${BASE_URL}${localizePathBySite('/blog/what-is-shopify', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'What is Shopify?',
      },
      description: t('nav:whatIsShopifyDescription'),
    });
  }

  // In case if we don`t have any items we return
  // just params for correct block bluilding and remove titles
  if (navItems.length === 0) {
    return {
      columnStart: 4,
      columnSpan: 6,
    };
  }

  return {
    content: t('nav:popularTopics'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Popular topics Nav',
    },
    columnStart: 4,
    columnSpan: 6,
    splitColumns: true,
    icon: 'popularTopics',
    navItems,
  };
}
