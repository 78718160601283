import type {I18NOptions, I18nRequestContext} from '@/types';

import {getSolutionsNav} from './solutions/getSolutionsNav';
import {getResourcesNav} from './resources/getResourcesNav';
import {getWhatsNewNav} from './whatsNew/getWhatsNewNav';
import {BASE_URL} from './constants';

interface DefaultMainNavArgs {
  translations: I18NOptions;
}

export const getEngineeringMainNav = ({
  translations: {t, localizePath, site},
}: DefaultMainNavArgs) => {
  const gaEvent = 'Main Nav';
  const i18nRequestContext: I18nRequestContext = {
    t,
    site,
  };

  const navItems: (
    | ReturnType<typeof getSolutionsNav>
    | ReturnType<typeof getResourcesNav>
    | any
  )[] = [
    getSolutionsNav(i18nRequestContext),
    {
      content: t('nav:pricing'),
      url: `${BASE_URL}${localizePath('/pricing')}`,
      data: {
        gaEvent,
        gaAction: 'Pricing Nav',
      },
    },
    getResourcesNav(i18nRequestContext),
  ];

  if (site.features.includes('editions') || site.languageCode === 'en') {
    const whatsNewItems: any = getWhatsNewNav(i18nRequestContext);
    if (whatsNewItems) {
      navItems.push(whatsNewItems);
    }
  }

  return navItems;
};
