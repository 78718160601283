import {linkpopUrl} from '@/hooks/navigation/utils';
import type {I18nRequestContext} from '@/types';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function getToolsNav({t, site}: I18nRequestContext) {
  const navItems = [
    {
      content: t('nav:businessNameGenerator'),
      url: `${BASE_URL}/tools/business-name-generator`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Business name generator',
      },
    },
    {
      content: t('nav:logoMaker'),
      url: `${BASE_URL}/tools/logo-maker`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Logo maker',
      },
    },
    {
      content: t('nav:stockPhotography'),
      url: `${BASE_URL}/stock-photos`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Stock photography',
      },
    },
    {
      content: t('nav:linkInBioTool'),
      url: linkpopUrl(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Link in bio tool',
      },
    },
    {
      content: t('nav:qrCodeGenerator'),
      url: `${BASE_URL}/tools/qr-code-generator`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'QR code generator',
      },
    },
  ];

  return {
    content: t('nav:essentialTools'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Essential tools Nav',
    },
    columnStart: 10,
    columnSpan: 3,
    aside: true,
    icon: 'essentialTools',
    navItems,
  };
}
