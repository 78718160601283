export enum EN_BLOG_PATH {
  CORE = '/blog/',
  RETAIL = '/retail/',
  PARTNERS = '/partners/blog/',
  ENTERPRISE = '/enterprise/blog/',
  ENGINEERING = '',
}

export const RETAIL_BLOG_LIGHT_BUTTON_AVAILABLE_LOCALES = [
  'en',
  'en-CA',
  'en-NZ',
  'en-GB',
  'en-AU',
  'en-SG',
  'en-IE',
];

export const BLOG_ARTICLE_ID = 'article-content';
