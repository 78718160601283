import type {I18nRequestContext} from '@/types';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {main} from './main';

export function getWhatsNewNav(i18nRequestContext: I18nRequestContext) {
  const {t} = i18nRequestContext;
  return {
    content: t('nav:whatsNew'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: "What's New",
    },
    navItems: [main(i18nRequestContext)],
  };
}
