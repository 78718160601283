import {DarkShopifyLogo} from '@/components/brochureV2/section/Header/svg/ShopifyLogo';

import {BASE_URL} from './config/constants';

export default function ShopifyLogo() {
  return (
    <div className="nav:w-[125px] mr-12 w-[98px] shrink-0">
      <a href={BASE_URL} data-component-name="logo-home">
        <DarkShopifyLogo />
      </a>
    </div>
  );
}
