import type {I18nRequestContext} from '@/types';
import {helpUrl} from '@/hooks/navigation/utils';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

export function getHelpNav({t, site}: I18nRequestContext) {
  const navItems = [
    {
      content: t('nav:helpAndSupport'),
      url: helpUrl(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Help and support',
      },
      description: t('nav:helpAndSupportDescription'),
    },
    {
      content: t('nav:compass'),
      url: 'https://academy.shopify.com',
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Business courses',
      },
      description: t('nav:compassDescription'),
    },
  ];

  return {
    content: t('nav:helpAndSupport'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Help and support Nav',
    },
    columnStart: 1,
    columnSpan: 3,
    icon: 'helpAndSupport',
    navItems,
  };
}
