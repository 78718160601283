import {localizePathBySite} from '@/utils/site';
import type {I18nRequestContext, NavigationListItem} from '@/types';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function getManageNav({t, site}: I18nRequestContext) {
  const navItems: NavigationListItem[] = [
    {
      content: t('nav:manageYourBusiness'),
      url: `${BASE_URL}${localizePathBySite('/manage', site)}`,
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Manage your business',
      },
      description: t('nav:manageYourBusinessDescription'),
    },
    {
      content: t('nav:measureYourPerformance'),
      url: `${BASE_URL}${localizePathBySite('/analytics', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Measure your Performance',
      },
      description: t('nav:measureYourPerformanceDescription'),
    },
  ];

  if (site.features.includes('shipping')) {
    navItems.push({
      content: t('nav:shippingV2'),
      url: `${BASE_URL}${localizePathBySite('/shipping', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Ship orders faster',
      },
      description: t('nav:shippingDescriptionV2'),
    });
  }

  navItems.push({
    content: t('nav:inventoryOrderManagement'),
    url: `${BASE_URL}${localizePathBySite('/orders', site)}`,
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Organize inventory and orders',
    },
    description: t('nav:inventoryOrderManagementDescription'),
  });

  if (site.features.includes('fulfillment')) {
    navItems.push({
      content: t('nav:fulfillmentV2'),
      url: `${BASE_URL}${localizePathBySite('/fulfillment', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Organize inventory and orders',
      },
      description: t('nav:fulfillmentDescription'),
    });
  }

  if (site.features.includes('balance')) {
    navItems.push({
      content: t('nav:moneyManagement'),
      url: `${BASE_URL}${localizePathBySite('/balance', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Get paid faster',
      },
      description: t('nav:moneyManagementDescription'),
    });
  }

  if (site.features.includes('capital')) {
    navItems.push({
      content: t('nav:businessFunding'),
      url: `${BASE_URL}${localizePathBySite('/capital', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Secure business funding',
      },
      description: t('nav:businessFundingDescription'),
    });
  }

  navItems.push({
    content: t('nav:flowV2'),
    url: `${BASE_URL}${localizePathBySite('/flow', site)}`,
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Automate your business',
    },
    description: t('nav:flowDescription'),
  });

  return {
    content: t('nav:manage'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Manage Nav',
    },
    columnStart: 10,
    columnSpan: 3,
    icon: 'manage',
    navItems,
  };
}
