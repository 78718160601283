import type {ReactNode} from 'react';
import type {VariantProps} from 'class-variance-authority';
import {cx} from 'class-variance-authority';

import Typography from '@/components/base/elements/Typography/Typography';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import {type SupportedComponentTypes} from '@/utils/hoc/withRichField';

import {
  EngineeringBlogConversionWrapperStyles,
  EngineeringBlogConversionStyles,
  HeadingStyles,
  SubheadStyles,
  ButtonStyles,
} from './styles';

interface EngineeringBlogConversionProps
  extends VariantProps<typeof EngineeringBlogConversionStyles> {
  heading: string;
  subhead?: string;
  buttonHref: string;
  buttonText: string;
  lightButton?: {text: string; href: string};
  children?: ReactNode;
  headingAs?: SupportedComponentTypes;
  buttonSize?: 'small' | 'large';
  conversionWrapperStyles?: string;
  componentName?: string;
}

export const EngineeringBlogConversion = ({
  heading,
  subhead,
  buttonHref,
  buttonText,
  children,
  conversionWrapperStyles = '',
  mode = 'dark',
  headingAs = 'span',
  buttonSize = 'large',
  componentName,
}: EngineeringBlogConversionProps) => {
  const buttons = [
    {
      href: buttonHref,
      text: buttonText,
      size: buttonSize,
      external: true,
      className: 'w-[273px] sm:w-full',
    },
  ];

  return (
    <div
      className={EngineeringBlogConversionWrapperStyles({mode})}
      data-component-name={componentName}
    >
      <div
        className={cx(
          EngineeringBlogConversionStyles({mode}),
          conversionWrapperStyles,
        )}
      >
        <Typography as={headingAs} className={HeadingStyles({mode})}>
          {heading}
        </Typography>
        {subhead && (
          <Typography className={SubheadStyles({mode})}>{subhead}</Typography>
        )}
        <ButtonGroup
          className={ButtonStyles({mode})}
          center
          buttons={buttons}
        />
        {children}
      </div>
    </div>
  );
};
