import {type HydratedT} from '@/components/shared/Page/I18N';
import type {LinkComponent} from '@/types';

export const subNavLinks = (
  t: HydratedT,
  localizePath: (path: string) => string,
  enBlogPath: string,
) => {
  return () => ({
    sectionTitle: t('subNav.title'),
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: t('subNav.pageTitle'),
    overviewText: t('subNav.overviewText'),
    links: [
      {
        text: t('subNav.links.development'),
        url: localizePath(`${enBlogPath}topics/development`),
        name: 'development',
      },
      {
        text: t('subNav.links.infrastructure'),
        url: localizePath(`${enBlogPath}topics/infrastructure`),
        name: 'infrastructure',
      },
      {
        text: t('subNav.links.mobile'),
        url: localizePath(`${enBlogPath}topics/mobile`),
        name: 'mobile',
      },
      {
        text: t('subNav.links.developer-tooling'),
        url: localizePath(`${enBlogPath}topics/developer-tooling`),
        name: 'developer-tooling',
      },
      {
        text: t('subNav.links.latest'),
        url: localizePath(`${enBlogPath}latest`),
        name: 'latest',
      },
      {
        text: t('subNav.links.moreTopics'),
        name: 'more',
        links: [
          {
            text: t('subNav.subLinks.security'),
            url: localizePath(`${enBlogPath}topics/security`),
            name: 'security',
          },
          {
            text: t('subNav.subLinks.data-science-engineering'),
            url: localizePath(`${enBlogPath}topics/data-science-engineering`),
            name: 'data-science-engineering',
          },
          {
            text: t('subNav.subLinks.culture'),
            url: localizePath(`${enBlogPath}topics/culture`),
            name: 'culture',
          },
        ],
      },
    ] as LinkComponent[],
  });
};
