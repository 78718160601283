import {cva} from 'class-variance-authority';

export const EngineeringBlogConversionWrapperStyles = cva(
  'relative overflow-hidden',
  {
    variants: {
      mode: {
        light: ['bg-engineering-banner-bg'],
        dark: ['bg-engineering-banner-bg py-10 tablet-xl:py-20 bg-[#61d095]'],
      },
    },
  },
);

export const EngineeringBlogConversionStyles = cva(
  ['container relative flex flex-col items-center text-center'],
  {
    variants: {
      mode: {
        light: [
          'pt-16 tablet-xl:pt-12 pb-12 px-[40px] tablet-xl:px-[130px] desktop:px-[210px]',
        ],
        dark: ['py-10 tablet-xl:py-16'],
      },
    },
  },
);

export const HeadingStyles = cva(
  ['uppercase font-medium font-aktivgroteskextended'],
  {
    variants: {
      mode: {
        light: ['mb-2 text-2xl tablet:text-3xl max-w-[1000px]'],
        dark: [
          'mb-2 text-[28px] tablet-xl:text-[42px] desktop:text-[48px] max-w-[800px] tablet-xl:mb-6',
          'leading-[36px] tablet-xl:leading-[46px] desktop:leading-[56px] text-black',
        ],
      },
    },
  },
);

export const SubheadStyles = cva(['max-w-[680px]'], {
  variants: {
    mode: {
      light: ['text-lg text-sm mb-4'],
      dark: [
        'text-body-base tablet-xl:text-body-lg mb-2 tablet-xl:mb-9 text-black',
      ],
    },
  },
});

export const ButtonStyles = cva(['text-base relative z-20'], {
  variants: {
    mode: {
      light: [],
      dark: ['w-[273px] h-14'],
    },
  },
});
