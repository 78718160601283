import {localizePathBySite} from '@/utils/site';
import {inboxPathAlt} from '@/hooks/navigation/utils';
import type {I18nRequestContext} from '@/types';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function getMarketNav({t, site}: I18nRequestContext) {
  const navItems = [
    {
      content: t('nav:marketDescription'),
      url: `${BASE_URL}${localizePathBySite('/market', site)}`,
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Market your business',
      },
      description: t('nav:marketDescriptionV2'),
    },
    {
      content: t('nav:socialV2'),
      url: `${BASE_URL}${localizePathBySite('/facebook-instagram', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Market across social',
      },
      description: t('nav:socialDescription'),
    },
    {
      content: t('nav:inboxV2'),
      url: `${BASE_URL}${inboxPathAlt(site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'inbox',
      },
      description: t('nav:inboxDescriptionV2'),
    },
    {
      content: t('nav:shopifyEmail'),
      url: `${BASE_URL}${localizePathBySite('/email-marketing', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Shopify Email',
      },
      description: t('nav:shopifyEmailDescription'),
    },
    {
      content: t('nav:customerInsights'),
      url: `${BASE_URL}${localizePathBySite('/segmentation', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Know your customers',
      },
      description: t('nav:customerInsightsDescription'),
    },
  ];

  return {
    content: t('nav:market'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Market Nav',
    },
    columnStart: 7,
    columnSpan: 3,
    icon: 'market',
    navItems,
  };
}
