import type {I18nRequestContext} from '@/types';
import {localizePathBySite} from '@/utils/site';
import {appLocalizedUrl, themesUrl} from '@/hooks/navigation/utils';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function getStartNav({t, site}: I18nRequestContext) {
  const navItems = [
    {
      content: t('nav:startYourBusiness'),
      url: `${BASE_URL}${localizePathBySite('/start', site)}`,
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Start',
      },
      description: t('nav:startYourBusinessDescription'),
    },
    {
      content: t('nav:ecommerceV2'),
      url: `${BASE_URL}${localizePathBySite('/website/builder', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Create your website',
      },
      description: t('nav:ecommerceDescription'),
    },
    {
      content: t('nav:storeThemesDescription'),
      url: themesUrl(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Customize your store',
      },
      description: t('nav:storeThemes'),
    },
    {
      content: t('nav:appStore'),
      url: appLocalizedUrl(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Find business apps',
      },
      description: t('nav:appStoreDescription'),
    },
    {
      content: t('nav:domainsAndHosting'),
      url: `${BASE_URL}${localizePathBySite('/domains', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Own your site domain',
      },
      description: t('nav:domainsAndHostingDescription'),
    },
    {
      content: t('nav:freeBusinessTools'),
      url: `${BASE_URL}${localizePathBySite('/tools', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Explore free business tools',
      },
      description: t('nav:freeBusinessToolsDescription'),
    },
  ];

  return {
    content: t('nav:start'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Build Nav',
    },
    columnStart: 1,
    columnSpan: 3,
    icon: 'build',
    navItems,
  };
}
