import type {I18nRequestContext} from '@/types';
import {localizePathBySite} from '@/utils/site';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function getSolutionsAsideNav({t, site}: I18nRequestContext) {
  const navItems = [
    {
      content: t('nav:shopifyDevelopers'),
      url: 'https://shopify.dev',
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Shopify Developers',
      },
      description: t('nav:shopifyDevelopersDescription'),
      columnSpan: 3,
    },
    {
      content: t('nav:plus'),
      url: `${BASE_URL}${localizePathBySite('/plus', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Shopify Plus',
      },
      description: t('nav:shopifyPlusDescription'),
      columnSpan: 3,
    },
  ];

  if (site?.domain === 'www.shopify.com') {
    navItems.push({
      content: t('nav:commerceComponents'),
      url: `${BASE_URL}${localizePathBySite('/enterprise', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Commerce Components',
      },
      description: t('nav:commerceComponentsDescription'),
      columnSpan: 3,
    });
  }

  navItems.push({
    content: t('nav:allProducts'),
    url: `${BASE_URL}${localizePathBySite('/products', site)}`,
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'All products',
    },
    description: t('nav:allProductsDescription'),
    columnSpan: 3,
  });

  return {
    aside: true,
    columnStart: 1,
    columnSpan: 12,
    navItems,
  };
}
