import {useMemo} from 'react';

import Header from '@/components/brochureV2/section/Header/Header';
import {HeaderBackgroundColorScheme} from '@/enums';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import {NavigationSearch} from '@/components/pages/blog/NavigationSearch/NavigationSearch';
import {subNavLinks} from '@/pages/shopify.engineering/components/EngineeringBlogHeader/subNavLinks';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import ShopifyLogo from '@/pages/shopify.engineering/components/EngineeringBlogHeader/ShopifyLogo';

import {getEngineeringMainNav} from './config/getEngineeringMainNav';

interface EngineeringBlogHeaderProps {
  enBlogPath: string;
}

export const EngineeringBlogHeader = ({
  enBlogPath,
}: EngineeringBlogHeaderProps) => {
  const {t: sharedT} = useSharedTranslations(`pages/blogSubNav`);
  const {t, localizePath, site} = useTranslations();

  const secondaryNavFn = useMemo(() => {
    return subNavLinks(sharedT, localizePath, enBlogPath);
  }, [enBlogPath, localizePath, sharedT]);
  const colorTheme = HeaderBackgroundColorScheme.TransparentDark;

  const mainNav = useMemo(() => {
    return () =>
      getEngineeringMainNav({
        translations: {t, localizePath, site},
      });
  }, [t, localizePath, site]);

  return (
    <Header
      background={colorTheme}
      navigationSearch={
        <NavigationSearch
          enBlogPath={enBlogPath}
          searchText={t('global:globalNav.searchText')}
          searchPlaceholder={t('global:globalNav.searchPlaceholder')}
          searchPlaceholderMobile={t(
            'global:globalNav.searchPlaceholderMobile',
          )}
          theme={colorTheme}
          blogType={BLOG_PAGE_TYPE.ENGINEERING}
          inputWrapperClassName="lg:!bg-enginneering-dark lg:!bg-engineering-dark-bg bg-top bg-no-repeat"
        />
      }
      CustomDesktopCtaLinklist={null}
      CustomMobileCtaLinklist={null}
      CustomMobileMenuCtaLinklist={null}
      logo={<ShopifyLogo />}
      secondaryNavFn={secondaryNavFn}
      mainNavFn={mainNav}
    />
  );
};
