import type {I18nRequestContext} from '@/types';
import {UrlUtils} from '@/utils/UrlUtils';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function main({t, site}: I18nRequestContext) {
  const urlUtils = new UrlUtils(site);
  const navItems = [
    {
      content: t('nav:changelog'),
      url: urlUtils.getUrl('/', 'changelog'),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Changelog',
      },
      description: t('nav:changelogDescription'),
      columnSpan: 3,
      icon: 'changelog',
    },
    {
      content: t('nav:newsroom'),
      url: `${BASE_URL}/news`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Newsroom',
      },
      description: t('nav:newsroomDescription'),
      columnSpan: 3,
      icon: 'newsroom',
    },
  ];

  return {
    simple: true,
    columnStart: 1,
    columnSpan: 12,
    navItems,
  };
}
