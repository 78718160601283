import type {I18nRequestContext, NavigationListItem} from '@/types';
import {isShopPayAvailable} from '@/hooks/navigation/utils';
import {localizePathBySite} from '@/utils/site';
import {MAIN_NAV_GA_EVENT} from '@/components/brochureV2/section/Header/config/constants';

import {BASE_URL} from '../constants';

export function getSellNav({t, site}: I18nRequestContext) {
  const navItems: NavigationListItem[] = [
    {
      content: t('nav:sellYourProducts'),
      url: `${BASE_URL}${localizePathBySite('/sell', site)}`,
      separated: true,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell your products',
      },
      description: t('nav:sellYourProductsDescription'),
    },
  ];

  if (isShopPayAvailable(site)) {
    navItems.push({
      content: t('nav:checkoutV2'),
      url: `${BASE_URL}${localizePathBySite('/checkout', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Checkout customers',
      },
      description: t('nav:checkoutDescriptionV2'),
    });
  }

  navItems.push(
    {
      content: t('nav:sellOnline'),
      url: `${BASE_URL}${localizePathBySite('/online', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell online',
      },
      description: t('nav:sellOnlineDescription'),
    },
    {
      content: t('nav:salesChannelsV2'),
      url: `${BASE_URL}${localizePathBySite('/channels', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell on across channels',
      },
      description: t('nav:salesChannelsDescription'),
    },
  );

  if (site.features.includes('pos')) {
    navItems.push({
      content: t('nav:pointOfSaleV2'),
      url: `${BASE_URL}${localizePathBySite('/pos', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell in person',
      },
      description: t('nav:pointOfSaleDescription'),
    });
  }

  navItems.push(
    {
      content: t('nav:internationalSalesDescription'),
      url: `${BASE_URL}${localizePathBySite('/markets', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell globally',
      },
      description: t('nav:internationalSales'),
    },
    {
      content: t('nav:b2b'),
      url: `${BASE_URL}${localizePathBySite(
        '/plus/solutions/b2b-ecommerce',
        site,
      )}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Sell wholesale and direct',
      },
      description: t('nav:b2bDescription'),
    },
  );

  if (site.features.includes('payments_online')) {
    navItems.push({
      content: t('nav:paymentsV2'),
      url: `${BASE_URL}${localizePathBySite('/payments', site)}`,
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Accept online payments',
      },
      description: t('nav:paymentsDescription'),
    });
  }

  return {
    content: t('nav:sell'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Sell Nav',
    },
    columnStart: 4,
    columnSpan: 3,
    icon: 'sell',
    navItems,
  };
}
